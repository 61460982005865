<!-- Copyright (C) 2017, 2018 DecElite LLC - All Rights Reserved. May not be distributed or copied. -->
<template>
  <v-layout
    justify-space-around
    wrap>
    <v-flex
      xs12
      sm6>
      <v-container>
        <v-layout wrap>
          <v-flex
            xs12>
            <h2 class="display-1">Log In</h2>
            <p>Select how you would like to login</p>
            <div id="firebaseui-auth-container"/>
          </v-flex>
        </v-layout>
      </v-container>
    </v-flex>
  </v-layout>
</template>

<script>
import * as VGrid from 'vuetify/es5/components/VGrid'
import VBtn from 'vuetify/es5/components/VBtn'
import VTextField from 'vuetify/es5/components/VTextField'
import VIcon from 'vuetify/es5/components/VIcon'
import { mapState } from 'vuex'
import firebase from 'firebase/app'
import 'firebase/auth'
import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'

export default {
  computed: mapState({
    loggedIn: state => state.user.authenticated,
  }),
  data () {
    return {
      redirect: this.$route.query.redirect
        ? this.$route.query.redirect : '/documents',
    }
  },
  methods: {
    mountAuthUI: function () {
      // If the user has already logged in, just go!
      if (this.loggedIn) {
        this.$router.push(this.redirect)
        return
      }

      var uiConfig = {
        callbacks: {
          signInSuccessWithAuthResult: (authResult, redirectUrl) => {
            // we definitely set UserId multiple places, but we want it set as
            // soon as possible
            this.$ga.set('userId', authResult.user.uid)
            if (authResult.additionalUserInfo.isNewUser) {
              this.$ga.event('user', 'new', authResult.additionalUserInfo.providerId)
            }
            this.$ga.event('user', 'login', authResult.additionalUserInfo.providerId)
            // we catch this elsewhere
            // Per spec, we need this to return false to avoid having the
            // widget handle the redirect
            return false
          },
          signInFailure: (e) => {
            this.$ga.event('user', 'login-failure', e.code)
          },
        },
        // Disable account chooser OAuth UI which gets confusing.
        credentialHelper: firebaseui.auth.CredentialHelper.NONE,

        signInOptions: [
          firebase.auth.EmailAuthProvider.PROVIDER_ID,
          firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          firebase.auth.TwitterAuthProvider.PROVIDER_ID,
          firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        ],
        // Terms of service url.
        tosUrl:           '/help/legal/terms-of-service',
        // Privacy policy url.
        privacyPolicyUrl: '/help/legal/privacy-policy',
      }

      if (this.$root.firebaseAuthUi == null) {
        this.$root.firebaseAuthUi = new firebaseui.auth.AuthUI(firebase.auth())
      }
      // TODO: this should be delayed to wait for the document to be ready,
      //       as this can set the listener, we navigate away, then the eventual
      //       build fails.
      // relevant code:
      // https://github.com/firebase/firebaseui-web/blob/master/javascript/widgets/authui.js#L445
      this.$root.firebaseAuthUi.start('#firebaseui-auth-container', uiConfig)
    },
  },
  mounted () {
    this.mountAuthUI()
  },
  watch: {
    // Checking login can sometimes take a bit... this catches once it finishes
    loggedIn: function (newState) {
      this.$router.push(this.redirect)
    },
  },
  components: {
    ...VGrid,
    VBtn,
    VTextField,
    VIcon,
  },
}
</script>
